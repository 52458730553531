import * as React from 'react';

import LinkboxList from './LinkboxList';

const CoursesList = () => {
  const componentData = [
    {
      name: 'การเขียนโปรแกรมด้วยภาษา C',
      text: 'การเขียนโปรแกรมด้วยภาษา C',
      linkboxAriaLabel: 'ERPNext Implementation',
      linkAriaLabel: 'Go to the Courses page',
      href: '/courses/clang-101',
      imgProps: { 
        src: '/images/services/c-programming.png', 
        alt: 'การเขียนโปรแกรมด้วยภาษา C',
        height: 180,
        width: 280,
      },
    },
    {
      name: 'การเขียนโปรแกรมด้วยภาษา Python',
      text: 'การเขียนโปรแกรมด้วยภาษา Python',
      linkboxAriaLabel: 'ออกแบบและพัฒนาเว็ปไซต์',
      linkAriaLabel: 'Go to the Courses page',
      href: '/courses/python-101',
      imgProps: { 
        src: '/images/services/python-programming.png', 
        alt: 'การเขียนโปรแกรมด้วยภาษา Python',
        height: 180,
        width: 280,
      },
    },
    {
      name: 'การสร้างเว็บไซต์ Portfolio สำหรับนักเรียน นักศึกษา',
      text: 'การสร้างเว็บไซต์ Portfolio  ด้วย Astro.js สำหรับนักเรียน นักศึกษา',
      linkboxAriaLabel: 'การสร้างเว็บไซต์ Portfolio ด้วย Astro.js สำหรับนักเรียน นักศึกษา',
      linkAriaLabel: 'Go to the Courses page',
      href: '/courses/portfolio-101',
      imgProps: { 
        src: '/images/services/porfolio1012.png', 
        alt: 'การสร้างเว็บไซต์ Portfolio สำหรับนักเรียน นักศึกษา',
        height: 180,
        width: 280,
      },
    },
    {
      name: 'Electronics for Kid 101',
      text: 'Electronics for Kid 101',
      linkboxAriaLabel: 'Electronics for Kid 101',
      linkAriaLabel: 'Go to the Courses page',
      href: '/courses/electronics-101',
      imgProps: { 
        src: '/images/services/electronis101.png', 
        alt: 'Electronics for Kid 101',
        height: 180,
        width: 280,
      },
    },
    
   

  ];

  return <LinkboxList data={componentData} />;
};

export default CoursesList;
